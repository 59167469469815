"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEV_LINK = exports.RESERVE_URLS = exports.RESERVE_CDN_FILE_PATH = exports.SUPPORT_CHAT_INTERFACE = exports.SUPPORT_CHAT_MODULE = void 0;
exports.SUPPORT_CHAT_MODULE = 'SUPPORT_CHAT_MODULE';
exports.SUPPORT_CHAT_INTERFACE = 'SUPPORT_CHAT_INTERFACE';
exports.RESERVE_CDN_FILE_PATH = 'active/index.js';
exports.RESERVE_URLS = {
    'https://support-backend.usrsprt-rc.xyz': 'https://cdn.usrsprt-rc.xyz/builds',
    'https://support-backend.usrsprt.com': 'https://cdn.usrsprt.com/builds',
};
exports.DEV_LINK = 'https://cdn.usrsprt.xyz/builds';
