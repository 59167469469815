"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.unmountChat = exports.hideChat = exports.showChat = exports.isChatActive = exports.updateUserData = exports.updateChat = exports.initChat = void 0;
const helpers_1 = require("./helpers");
const constants_1 = require("./constants");
const getChatModule = () => global[constants_1.SUPPORT_CHAT_MODULE];
const initChat = (_a) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    var { url } = _a, options = __rest(_a, ["url"]);
    let urlToChat = '';
    if (!getChatModule()) {
        try {
            const data = yield (0, helpers_1.callForVersion)(url);
            urlToChat = data.url || (0, helpers_1.getReserveChatUrl)(url);
        }
        catch (e) {
            const reserveUrl = (0, helpers_1.getReserveChatUrl)(url);
            urlToChat = reserveUrl;
        }
        const timestamp = (0, helpers_1.roundToNearest5)();
        yield (0, helpers_1.injectScript)(`${urlToChat}?i=${timestamp}`);
    }
    (_b = getChatModule()) === null || _b === void 0 ? void 0 : _b.initChat(options);
});
exports.initChat = initChat;
const updateChat = (options) => {
    const chatModule = getChatModule();
    if (!chatModule) {
        console.error('Chat was not initiated');
        return;
    }
    if (!chatModule.updateChat) {
        console.error('Chat module does not have updateChat method');
        return;
    }
    chatModule.updateChat(options);
};
exports.updateChat = updateChat;
const updateUserData = (options) => {
    const chatModule = getChatModule();
    if (!chatModule) {
        console.error('Chat was not initiated');
        return;
    }
    if (!chatModule.updateUserData) {
        console.error('Chat module does not have updateUserData method');
        return;
    }
    chatModule.updateUserData(options);
};
exports.updateUserData = updateUserData;
const isChatActive = () => {
    const chatModule = getChatModule();
    if (!chatModule) {
        console.error('Chat was not initiated');
        return;
    }
    if (!chatModule.isChatActive) {
        console.error('Chat module does not have isChatActive method');
        return;
    }
    return chatModule.isChatActive();
};
exports.isChatActive = isChatActive;
const showChat = () => {
    const chatModule = getChatModule();
    if (!chatModule) {
        console.error('Chat was not initiated');
        return;
    }
    if (!chatModule.showChat) {
        console.error('Chat module does not have showChat method');
        return;
    }
    chatModule.showChat();
};
exports.showChat = showChat;
const hideChat = () => {
    const chatModule = getChatModule();
    if (!chatModule) {
        console.error('Chat was not initiated');
        return;
    }
    if (!chatModule.hideChat) {
        console.error('Chat module does not have hideChat method');
        return;
    }
    chatModule.hideChat();
};
exports.hideChat = hideChat;
const unmountChat = () => {
    const chatModule = getChatModule();
    if (!chatModule) {
        console.error('Chat was not initiated');
        return;
    }
    if (!chatModule.unmountChat) {
        console.error('Chat module does not have unmountChat method');
        return;
    }
    chatModule.unmountChat();
};
exports.unmountChat = unmountChat;
exports.default = { initChat: exports.initChat, updateChat: exports.updateChat, updateUserData: exports.updateUserData, isChatActive: exports.isChatActive, showChat: exports.showChat, hideChat: exports.hideChat, unmountChat: exports.unmountChat };
