"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReserveChatUrl = exports.getHostUrl = exports.callForVersion = exports.roundToNearest5 = exports.injectScript = void 0;
const constants_1 = require("./constants");
const injectScript = (src) => new Promise((resolve, reject) => {
    const wasScriptLoaded = global.document.querySelector(`script[src="${src}"]`);
    if (wasScriptLoaded) {
        resolve();
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = src;
    script.addEventListener('load', () => {
        resolve();
    });
    script.addEventListener('error', () => {
        reject(new Error('Error loading script.'));
    });
    script.addEventListener('abort', () => {
        reject(new Error('Script loading aborted.'));
    });
    document.head.appendChild(script);
});
exports.injectScript = injectScript;
const roundToNearest5 = (date = new Date()) => {
    const minutes = 5;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.round(date.getTime() / ms) * ms).valueOf();
};
exports.roundToNearest5 = roundToNearest5;
const callForVersion = (url = '') => fetch(url).then((response) => response.json());
exports.callForVersion = callForVersion;
const getHostUrl = (url) => {
    const urlHost = url.match(/(https?:\/\/[^/]+)/);
    return (urlHost && urlHost[0]) || '';
};
exports.getHostUrl = getHostUrl;
const getReserveChatUrl = (url) => {
    var _a, _b;
    try {
        const urlHost = (0, exports.getHostUrl)(url);
        const companyName = ((_a = url.match(/\w+$/)) === null || _a === void 0 ? void 0 : _a[0]) || '';
        if (constants_1.RESERVE_URLS[urlHost]) {
            return `${constants_1.RESERVE_URLS[urlHost]}/chat/${companyName}/${constants_1.RESERVE_CDN_FILE_PATH}`;
        }
        const regex = /support-backend-(\w+)\.usrsprt\.xyz/;
        const devName = ((_b = url.match(regex)) === null || _b === void 0 ? void 0 : _b[1]) || '';
        return `${constants_1.DEV_LINK}/${devName}/chat/${companyName}/${constants_1.RESERVE_CDN_FILE_PATH}`;
    }
    catch (e) {
        console.error('Chat wasn\'t loaded');
        return '';
    }
};
exports.getReserveChatUrl = getReserveChatUrl;
